/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(/static/source-sans-pro-v13-latin-300.e9e14f1e2f7e050c66ea2e14a67d5d65.eot); /* IE9 Compat Modes */
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url(/static/source-sans-pro-v13-latin-300.e9e14f1e2f7e050c66ea2e14a67d5d65.eot?#iefix) format('embedded-opentype'), 
       url(/static/source-sans-pro-v13-latin-300.ede18477b85a5d781cd2f4001ecc5e67.woff2) format('woff2'), 
       url(/static/source-sans-pro-v13-latin-300.a5002963b0570a073e28156403c78670.woff) format('woff'), 
       url(/static/source-sans-pro-v13-latin-300.8a5f548004e5d929f439c4a6eb822105.ttf) format('truetype'), 
       url(/static/source-sans-pro-v13-latin-300.e9e6872c64575ad041c2e23a3285200b.svg#SourceSansPro) format('svg'); /* Legacy iOS */
}

/* source-sans-pro-300italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url(/static/source-sans-pro-v13-latin-300italic.2dc97beedae441051054605bde23069b.eot); /* IE9 Compat Modes */
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
       url(/static/source-sans-pro-v13-latin-300italic.2dc97beedae441051054605bde23069b.eot?#iefix) format('embedded-opentype'), 
       url(/static/source-sans-pro-v13-latin-300italic.c179c8f264df4b31eab6c71a532f7599.woff2) format('woff2'), 
       url(/static/source-sans-pro-v13-latin-300italic.f939f20b37caaa8e99bcd2e0ef22436c.woff) format('woff'), 
       url(/static/source-sans-pro-v13-latin-300italic.a00b3aa72a13e2b6f753268f07e99a01.ttf) format('truetype'), 
       url(/static/source-sans-pro-v13-latin-300italic.b1e33f9ae4285304d576fa24f0953755.svg#SourceSansPro) format('svg'); /* Legacy iOS */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(/static/source-sans-pro-v13-latin-regular.8ddcafb98403e3c126c5b64545911b0c.eot); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url(/static/source-sans-pro-v13-latin-regular.8ddcafb98403e3c126c5b64545911b0c.eot?#iefix) format('embedded-opentype'), 
       url(/static/source-sans-pro-v13-latin-regular.899c8f78ce650d4009d42443897aa723.woff2) format('woff2'), 
       url(/static/source-sans-pro-v13-latin-regular.5cc3aae674ea3b199313b3b83bd795bc.woff) format('woff'), 
       url(/static/source-sans-pro-v13-latin-regular.123352716b6df5cc7d06e6edb5f9554b.ttf) format('truetype'), 
       url(/static/source-sans-pro-v13-latin-regular.73dea83f2060b257888982da2375c343.svg#SourceSansPro) format('svg'); /* Legacy iOS */
}

/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url(/static/source-sans-pro-v13-latin-italic.90e0cd5f1aa3786e4bc3ed4cc587c0e8.eot); /* IE9 Compat Modes */
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url(/static/source-sans-pro-v13-latin-italic.90e0cd5f1aa3786e4bc3ed4cc587c0e8.eot?#iefix) format('embedded-opentype'), 
       url(/static/source-sans-pro-v13-latin-italic.dac2fca7ba8512baa24172f1171c961f.woff2) format('woff2'), 
       url(/static/source-sans-pro-v13-latin-italic.86b2389fa562da6b9425271d1833d490.woff) format('woff'), 
       url(/static/source-sans-pro-v13-latin-italic.29806f1a2be3f1988ce07f15f06ddb03.ttf) format('truetype'), 
       url(/static/source-sans-pro-v13-latin-italic.393c785a09abc2239914dbd79f99e75d.svg#SourceSansPro) format('svg'); /* Legacy iOS */
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url(/static/source-sans-pro-v13-latin-600.4e138ceddd984e6d66bcfa1ba1a041d4.eot); /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url(/static/source-sans-pro-v13-latin-600.4e138ceddd984e6d66bcfa1ba1a041d4.eot?#iefix) format('embedded-opentype'), 
       url(/static/source-sans-pro-v13-latin-600.c85615b296302af51e683eecb5e371d4.woff2) format('woff2'), 
       url(/static/source-sans-pro-v13-latin-600.a75563d7b9e5b1db163971b9a2e66216.woff) format('woff'), 
       url(/static/source-sans-pro-v13-latin-600.32f95a4b3e3d909ec3d5c37208f55d5d.ttf) format('truetype'), 
       url(/static/source-sans-pro-v13-latin-600.1b3c5a77c373ce28388c8050d09bc7d1.svg#SourceSansPro) format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(/static/source-sans-pro-v13-latin-700.f7db7429119454dc55845cd4d4013586.eot); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url(/static/source-sans-pro-v13-latin-700.f7db7429119454dc55845cd4d4013586.eot?#iefix) format('embedded-opentype'), 
       url(/static/source-sans-pro-v13-latin-700.1a4bcb3ec9c508d478d4dbf6b56f6208.woff2) format('woff2'), 
       url(/static/source-sans-pro-v13-latin-700.b03f2ec28f8e60e61974dd8c57610e5b.woff) format('woff'), 
       url(/static/source-sans-pro-v13-latin-700.07d81517c33a1f61c53338fc6221e8e5.ttf) format('truetype'), 
       url(/static/source-sans-pro-v13-latin-700.32495ff6143de8df9385f6b311d0d00c.svg#SourceSansPro) format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url(/static/source-sans-pro-v13-latin-700italic.14018a14b90c879c259f7adf6c4bbd4f.eot); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
       url(/static/source-sans-pro-v13-latin-700italic.14018a14b90c879c259f7adf6c4bbd4f.eot?#iefix) format('embedded-opentype'), 
       url(/static/source-sans-pro-v13-latin-700italic.ac7f680ecb10b2d7d452560d91e617d6.woff2) format('woff2'), 
       url(/static/source-sans-pro-v13-latin-700italic.15776eec451ff4c88330cc66edd9e1e4.woff) format('woff'), 
       url(/static/source-sans-pro-v13-latin-700italic.0d103851e409501c1cf30f9a2b5368a0.ttf) format('truetype'), 
       url(/static/source-sans-pro-v13-latin-700italic.276c07292e7718ed6087a7f54c89b588.svg#SourceSansPro) format('svg'); /* Legacy iOS */
}
