._1yRrLub0MIxeWrGGurSfpw {
  position: absolute;
  top: var(--bar-top-position);
  left: var(--bar-left-margin);
  bottom: var(--bar-bottom-position);
  background-color: var(--bar-color);
  width: var(--bar-width);
}

._1ohdMaVgmi-rBHlMBYlHHT {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

._1_ZzYyrQdcPl2PdBK2gqe9 {
  margin-left: calc(var(--bar-width) + var(--bar-left-margin) + 0.33rem);
}

._1od7BAQOD-GTUQz0_QqSSJ {
  color: rgb(128, 128, 128);
  font-size: 13px;
  font-size: 0.8125rem;
}

._3AlgfHNoYLsXDEr0jSNxMC {
  --bar-width: var(--one-digit-bar-width);
  --bar-left-margin: var(--one-digit-bar-left-margin);
  --bar-top-position: 0;
  --bar-bottom-position: 0;
}

.hFCAcowrW1yDdn-N5iNmB {
  --bar-width: var(--two-digit-bar-width);
  --bar-left-margin: var(--two-digit-bar-left-margin);
  --bar-top-position: 17.6%;
  --bar-bottom-position: 17.6%;
}

._32i5Nt0qKt9SItz8q9mksH {
  --bar-width: var(--four-digit-bar-width);
  --bar-left-margin: var(--four-digit-bar-left-margin);
  --bar-top-position: 17.6%;
  --bar-bottom-position: 17.6%;
}

._1VtCw1RqqQpIJ1BmMHJ8UK {
  --bar-width: var(--six-digit-bar-width);
  --bar-left-margin: var(--six-digit-bar-left-margin);
  --bar-top-position: 17.6%;
  --bar-bottom-position: 17.6%;
}
