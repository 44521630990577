.htXWNFZUOmIhcRXPAe5Jc {
  -ms-flex-preferred-size: 25px;
      flex-basis: 25px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 10px;
  border: 1px solid #ddd;
}

._1-naDVwGGl1yco8XosAU1t {
  -ms-flex-preferred-size: calc(100% - 25px);
      flex-basis: calc(100% - 25px);
}

._36jNsKElviiS4gFmm01cik {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
