._27NNcaH5PNOZjC_rs3A92x {
  --highlight-color: rgba(0, 126, 255, .08)
}

._27NNcaH5PNOZjC_rs3A92x:hover {
  background-color: var(--highlight-color);
}

._3MY168LyITEwUzN0VRFylx {
  background-color: var(--highlight-color);
}

._3QisW4izdYo5D_gslRU01A {
  background-color: inherit;
}

.WQ5vHJhAc8Jee81m_hBAA {
  position: absolute;
}

._1h2sZ-eQ-oSaqm6PhZUG20 {
  height: 100%;
}
